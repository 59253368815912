var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Пробуем получить таймслоты")]),
      _c("p", [_vm._v("Терминал:")]),
      _c("p", [_vm._v(_vm._s(_vm.formData.unload_id))]),
      _c("p", [_vm._v("Количество авто:")]),
      _c("p", [_vm._v(_vm._s(_vm.formData.requests_count))]),
      _c("p", [_vm._v("Дата и время:")]),
      _c("p", [_vm._v(_vm._s(_vm.formData.time))]),
      _c("p", [_vm._v("Культура:")]),
      _c("p", [_vm._v(_vm._s(_vm.getCultureName.name))]),
      _c("p", [_vm._v("Поставщик:")]),
      _c("p", [_vm._v(_vm._s(_vm.formData.supplier_id))]),
      _c("el-progress", {
        attrs: {
          "text-inside": true,
          "stroke-width": 24,
          percentage: 81,
          status: "success",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }