<template>
  <div>
    <h3>Пробуем получить таймслоты</h3>
    <p>Терминал:</p>
    <p>{{ formData.unload_id }}</p>
    <p>Количество авто:</p>
    <p>{{ formData.requests_count }}</p>
    <p>Дата и время:</p>
    <p>{{ formData.time }}</p>
    <p>Культура:</p>
    <p>{{ getCultureName.name }}</p>
    <p>Поставщик:</p>
    <p>{{ formData.supplier_id }}</p>
    <el-progress
      :text-inside="true"
      :stroke-width="24"
      :percentage="81"
      status="success"
    />
  </div>
</template>
<script>
import { DR_GET_CULTURES } from '@/store/actions'
import { mapGetters } from 'vuex'

export default {
  name: 'StatusRequestSlots',
  props: {
    formData: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      getCultures: DR_GET_CULTURES,
    }),
    getCultureName() {
      return this.getCultures.filter(
        item => item.id === this.formData.culture_id,
      )
    },
  },
}
</script>
